import L from 'leaflet';
import { invalidateSize } from '../../mixins/map';

let map, currentMapIcon;
const { lat = '', lng = '' } = REALPRESS_SINGLE_PROPERTY_PAGE.map;
//Layer
const tileLayer = L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
} );

const realpressMap = () => {
	if ( ! lat || ! lng || REALPRESS_GLOBAL_OBJECT.map_type !== 'open_street_map' ) {
		return;
	}
	const mapIdNode = document.querySelector( '#realpress-single-property-map' );
	if ( ! mapIdNode ) {
		return;
	}
	mapInit();
};

const mapInit = () => {
	const markerCoord = [ lat, lng, 17 ];
	const markerOptions = {
		draggable: false,
	};
	//Map Options
	const mapOptions = {
		layers: [ tileLayer ],
		center: [ lat, lng, 17 ],
		zoom: 13,
		attributionControl: false,
		scrollWheelZoom: false,
		zoomControl: false,

	};

	map = L.map( 'realpress-single-property-map', mapOptions ).setView( [ lat, lng, 17 ], 13 );
	L.control.zoom( {
		position: 'topright',
	} ).addTo( map );

	currentMapIcon = L.marker( markerCoord, markerOptions ).addTo( map );
	invalidateSize( map );
};

export { map };
export default realpressMap;
