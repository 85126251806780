import { invalidateSize } from '../../mixins/map';
import { map } from './osm-map';

const realpressMediaNavigation = () => {
	const mediaNode = document.querySelector( '.realpress-property-media' );
	if ( ! mediaNode ) {
		return;
	}
	const navigationNodes = mediaNode.querySelectorAll( '.realpress-media-navigation .nav-item' );
	const panelItemNodes = mediaNode.querySelectorAll( '.realpress-media-panel-wrapper .panel-item' );

	if ( navigationNodes.length ) {
		navigationNodes[ 0 ].classList.add( 'active' );
		panelItemNodes[ 0 ].classList.add( 'active' );
	}
	if ( navigationNodes.length ) {
		for ( let i = 0; i < navigationNodes.length; i++ ) {
			navigationNodes[ i ].addEventListener( 'click', function( event ) {
				event.preventDefault();
				const selectedNode = this;
				for ( let i = 0; i < navigationNodes.length; i++ ) {
					const navigation = navigationNodes[ i ];
					if ( navigation === selectedNode ) {
						navigation.classList.add( 'active' );
					} else {
						navigation.classList.remove( 'active' );
					}
				}

				const selectedPanelId = selectedNode.getAttribute( 'data-item' );
				for ( let j = 0; j < panelItemNodes.length; j++ ) {
					const panelItem = panelItemNodes[ j ];
					const panelId = panelItem.getAttribute( 'id' );
					if ( panelItem.classList.contains( 'active' ) ) {
						if ( panelId !== selectedPanelId ) {
							panelItem.classList.remove( 'active' );
						}
					} else if ( panelId === selectedPanelId ) {
						panelItem.classList.add( 'active' );
						if ( selectedPanelId === 'realpress-map-panel' && REALPRESS_GLOBAL_OBJECT.map_type === 'open_street_map' ) {
							invalidateSize( map );
						}
					}
				}
			} );
		}
	}
};

export default realpressMediaNavigation;
