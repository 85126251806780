import { toggle } from 'slidetoggle';

const realpressContactForm = () => {
	const contactFormNodes = document.querySelectorAll( '.realpress-contact-form-section' );
	if ( ! contactFormNodes ) {
		return;
	}
	for ( let i = 0; i < contactFormNodes.length; i++ ) {
		const contactFormNode = contactFormNodes[ i ];
		const formNode = contactFormNode.querySelector( 'form' );
		if ( ! formNode ) {
			return;
		}
		contactFormNode.querySelector( '.realpress-agent-detail i' ).addEventListener( 'click', function() {
			toggle( formNode, {
				miliseconds: 300,
			} );
			contactFormNode.classList.toggle( 'active' );
		} );
	}
};

export default realpressContactForm;
