const { lat = '', lng = '' } = REALPRESS_SINGLE_PROPERTY_PAGE.map;
let mapIdNode;

const realpressMap = () => {
	if ( ! lat || ! lng || REALPRESS_GLOBAL_OBJECT.map_type !== 'google_map' ) {
		return;
	}
	mapIdNode = document.querySelector( '#realpress-single-property-map' );
	if ( ! mapIdNode ) {
		return;
	}
	mapInit();
};

const mapInit = () => {
	const latLng = new google.maps.LatLng( lat, lng );
	const zoom = 13;
	//Map Options
	const mapOptions = {
		center: latLng, zoom,
		mapTypeControl: true,
		mapTypeControlOptions: {
			style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			position: google.maps.ControlPosition.TOP_LEFT,
		},
	};

	const map = new google.maps.Map( mapIdNode, mapOptions );

	const marker = new google.maps.Marker( {
		position: latLng,
		draggable: false,
		map,
	} );
};

export default realpressMap;
