import Splide from '@splidejs/splide';

const realpressGallerySilder = () => {
	const mainGallery = document.querySelector( '#realpress-main-gallery' );
	if ( ! mainGallery ) {
		return;
	}

	const thumbnailGallery = document.querySelector( '#realpress-thumbnail-gallery' );
	if ( ! thumbnailGallery ) {
		return;
	}

	const main = new Splide( '#realpress-main-gallery', {
		fixedHeight: 700,
		type: 'fade',
		rewind: true,
		pagination: false,
		arrows: false,
	} );

	const thumbnail = new Splide( '#realpress-thumbnail-gallery', {
		fixedWidth: 150,
		fixedHeight: 90,
		gap: 10,
		rewind: true,
		pagination: false,
		isNavigation: true,
		breakpoints: {
			600: {
				fixedWidth: 90,
				fixedHeight: 60,
			},
		},
	} );

	main.sync( thumbnail );
	main.mount();
	thumbnail.mount();
};

export default realpressGallerySilder;
