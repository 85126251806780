const getOrderParams = ( selected ) => {
	let orderby, order;
	switch ( selected ) {
	case 'default':
		orderby = 'date';
		order = 'desc';
		break;
	case 'rating_asc':
		orderby = 'rating';
		order = 'asc';
		break;
	case 'rating_desc':
		orderby = 'rating';
		order = 'desc';
		break;
	default:
		orderby = 'date';
		order = 'desc';
		break;
	}

	return { orderby, order };
};

export { getOrderParams };
