import Splide from '@splidejs/splide';

const realpressSimilarSilder = () => {
	const similarSlide = document.querySelector( '#realpress-similar-property' );
	if ( ! similarSlide ) {
		return;
	}
	const main = new Splide( '#realpress-similar-property', {
		fixedHeight: 700,
		type: 'fade',
		rewind: true,
		pagination: true,
		arrows: true,
		perPage: 3,
	} );

	main.mount();

};

export default realpressSimilarSilder;
