import { Chart, registerables } from 'chart.js';
import { renderPrice } from '../../mixins/price';

const mcSectionNode = document.querySelector( '.realpress-mc-section' );
let mcChartDataGroupNode, mcFormNode, mcSubmitBtnNode;
let totalAmount, downPayment, loanTerm, propertyTax, pmi, interestRate, homeInsurance, calDoughnutChart;
let messageNode;
const { __ } = wp.i18n;

const init = () => {
	messageNode = mcSectionNode.querySelector( '.realpress-form-message' );
	mcChartDataGroupNode = mcSectionNode.querySelector( '.realpress-mc-chart-data-group' );
	mcFormNode = mcSectionNode.querySelector( 'form' );
	mcSubmitBtnNode = mcFormNode.querySelector( '.realpress-mc-submit button' );

	totalAmount = mcFormNode.querySelector( '#realpress-mc-form-total' ).value;
	downPayment = mcFormNode.querySelector( '#realpress-mc-form-down_payment' ).value;
	loanTerm = mcFormNode.querySelector( '#realpress-mc-form-loan-terms' ).value;
	propertyTax = mcFormNode.querySelector( '#realpress-mc-form-property-tax' ).value;
	pmi = mcFormNode.querySelector( '#realpress-mc-form-pmi' ).value;
	interestRate = mcFormNode.querySelector( '#realpress-mc-form-interest-rate' ).value;
	homeInsurance = mcFormNode.querySelector( '#realpress-mc-form-home-insurance' ).value;
};
const realpressMortgageCalculator = () => {
	if ( ! mcSectionNode ) {
		return;
	}

	init();
	handleMortgageCalculator();
	mcSubmitBtnNode.addEventListener( 'click', function( event ) {
		event.preventDefault();
		totalAmount = mcFormNode.querySelector( '#realpress-mc-form-total' ).value;
		downPayment = mcFormNode.querySelector( '#realpress-mc-form-down_payment' ).value;
		loanTerm = mcFormNode.querySelector( '#realpress-mc-form-loan-terms' ).value;
		propertyTax = mcFormNode.querySelector( '#realpress-mc-form-property-tax' ).value;
		pmi = mcFormNode.querySelector( '#realpress-mc-form-pmi' ).value;
		interestRate = mcFormNode.querySelector( '#realpress-mc-form-interest-rate' ).value;
		homeInsurance = mcFormNode.querySelector( '#realpress-mc-form-home-insurance' ).value;
		if ( totalAmount && downPayment && loanTerm && propertyTax && pmi && interestRate && homeInsurance ) {
			messageNode.classList.remove( 'failed' );
			messageNode.innerHTML = '';
			handleMortgageCalculator();
		} else {
			messageNode.classList.add( 'failed' );
			messageNode.innerHTML = __( 'Please fill out all fields', 'realpress' );
		}
		mcSectionNode.scrollIntoView();
	} );
};

const handleMortgageCalculator = () => {
	//https://www.businessinsider.com/personal-finance/how-to-calculate-mortgage-payment
	const paymentNumbers = loanTerm * 12;

	//monthly rate
	const principalLoanAmount = totalAmount - downPayment;
	const rate = interestRate / 100 / 12;
	const principalInterestPayment = principalLoanAmount * rate * ( Math.pow( 1 + rate, paymentNumbers ) ) / ( Math.pow( 1 + rate, paymentNumbers ) - 1 );
	if ( isNaN( principalInterestPayment ) ) {
		return;
	}
	mcChartDataGroupNode.querySelector( '#realpress-mc-principal-interest' ).innerHTML = renderPrice( principalInterestPayment );
	mcChartDataGroupNode.querySelector( '#realpress-mc-property_tax' ).innerHTML = renderPrice( propertyTax );
	mcChartDataGroupNode.querySelector( '#realpress-mc-home-insurance' ).innerHTML = renderPrice( homeInsurance );
	mcChartDataGroupNode.querySelector( '#realpress-mc-pmi' ).innerHTML = renderPrice( pmi );
	const mcPriceTotal = principalInterestPayment + parseFloat( propertyTax ) + parseFloat( homeInsurance ) + parseFloat( pmi );
	mcChartDataGroupNode.querySelector( '#realpress-mc-price' ).innerHTML = renderPrice( mcPriceTotal );
	const chartNode = mcChartDataGroupNode.querySelector( '.realpress-mc-chart canvas' ).getContext( '2d' );

	if ( typeof calDoughnutChart !== 'undefined' ) {
		calDoughnutChart.destroy();
	}

	Chart.register( ...registerables );
	calDoughnutChart = new Chart( chartNode, {
		type: 'doughnut',
		data: {
			datasets: [ {
				data: [ principalInterestPayment, propertyTax, homeInsurance, pmi ],
				backgroundColor: [ '#ACBEFF', '#FFC33E', '#24D2C8', '#3CB2F4' ],
				borderColor: [ '#ACBEFF', '#FFC33E', '#24D2C8', '#3CB2F4' ],
				borderWidth: 1,
			} ],
		},
		options: {
			cutoutPercentage: 85, responsive: true, tooltips: true,
			cutout: 85,
		},
	} );
};

export default realpressMortgageCalculator;
