import L from 'leaflet';

const markers = [];
let infowindow;

const invalidateSize = ( map ) => {
	setTimeout( function() {
		map.invalidateSize();
	}, 0 );
};

const getMapBounds = ( mapDataProperties ) => {
	const mapBounds = [];
	for ( let i = 0; i < mapDataProperties.length; i++ ) {
		if ( mapDataProperties[ i ].lat_lng.lat && mapDataProperties[ i ].lat_lng.lng ) {
			mapBounds.push( [ mapDataProperties[ i ].lat_lng.lat, mapDataProperties[ i ].lat_lng.lng ] );
		}
	}

	return mapBounds;
};

const addOSMMarker = ( properties, map ) => {
	for ( let i = 0; i < properties.length; i++ ) {
		if ( properties[ i ].lat_lng.lat && properties[ i ].lat_lng.lng ) {
			const lat = properties[ i ].lat_lng.lat;
			const lng = properties[ i ].lat_lng.lng;
			const { title, permalink } = properties[ i ];
			const formattedPrice = properties[ i ].formatted_price;
			const textAfterPrice = properties[ i ].text_after_price;
			const typeName = properties[ i ].type_name;
			const typeLink = properties[ i ].type_link;
			const thumbnail = properties[ i ].thumbnail_url;
			const propertyId = properties[ i ].id;

			const mapCenter = L.latLng( lat, lng );

			const markerOptions = {
				riseOnHover: true,
			};

			if ( title ) {
				markerOptions.title = title;
			}

			const marker = L.marker( mapCenter, markerOptions ).addTo( map );

			const popup = getPopupContent( permalink, thumbnail, title, formattedPrice, textAfterPrice, typeName, typeLink );
			//Set id for marker
			marker.id = propertyId;
			marker.bindPopup( popup );
		}
	}
};

const openPopup = ( map, propertyId, properties ) => {
	if ( ! map ) {
		return;
	}

	if ( REALPRESS_GLOBAL_OBJECT.map_type === 'open_street_map' ) {
		map.eachLayer( function( layer ) {
			if ( layer.id === parseInt( propertyId ) ) {
				layer.openPopup();
			}
		} );
	} else if ( REALPRESS_GLOBAL_OBJECT.map_type === 'google_map' ) {
		for ( let i = 0; i < markers.length; i++ ) {
			if ( markers[ i ].id === parseInt( propertyId ) ) {
				for ( let i = 0; i < properties.length; i++ ) {
					if ( properties[ i ].id === parseInt( propertyId ) ) {
						const { title, permalink } = properties[ i ];
						const formattedPrice = properties[ i ].formatted_price;
						const textAfterPrice = properties[ i ].text_after_price;
						const typeName = properties[ i ].type_name;
						const typeLink = properties[ i ].type_link;
						const thumbnail = properties[ i ].thumbnail_url;
						const popup = getPopupContent( permalink, thumbnail, title, formattedPrice, textAfterPrice, typeName, typeLink );
						infowindow.setContent( popup );
					}
				}
				infowindow.open( map, markers[ i ] );
			}
		}
	}
};

const closePopup = ( map, propertyId ) => {
	if ( ! map ) {
		return;
	}

	if ( REALPRESS_GLOBAL_OBJECT.map_type === 'open_street_map' ) {
		map.eachLayer( function( layer ) {
			if ( layer.id === parseInt( propertyId ) ) {
				layer.closePopup();
			}
		} );
	} else if ( REALPRESS_GLOBAL_OBJECT.map_type === 'google_map' ) {
		for ( let i = 0; i < markers.length; i++ ) {
			if ( markers[ i ].id === parseInt( propertyId ) ) {
				infowindow.close( map, markers[ i ] );
			}
		}
	}
};

const displayPropertyMapBound = ( map, properties, id ) => {
	if ( REALPRESS_GLOBAL_OBJECT.map_type === 'open_street_map' ) {
		const tileLayer = L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		} );

		const mapBounds = getMapBounds( properties );

		let mapCenter = null;
		if ( mapBounds.length === 1 ) {
			mapCenter = L.latLng( mapBounds[ 0 ] );
		}

		const mapOptions = {
			dragging: true, center: mapCenter, zoom: 10, tap: false, scrollWheelZoom: false, zoomSnap: 0.25, // fit map bound
		};

		if ( map !== undefined ) {
			map.remove();
		}

		map = L.map( id, mapOptions );
		map.addLayer( tileLayer );
		if ( 1 < mapBounds.length ) {
			map.fitBounds( mapBounds );
			//Set zoom level
			map.setZoom( map.getZoom() - 1 );
		}

		addOSMMarker( properties, map );
	} else if ( REALPRESS_GLOBAL_OBJECT.map_type === 'google_map' ) {
		const zoom = 13;
		//Map Options
		const mapOptions = {
			zoom,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.TOP_LEFT,
			},
		};

		const mapIdNode = document.querySelector( `#${ id }` );
		map = new google.maps.Map( mapIdNode, mapOptions );

		const bounds = new google.maps.LatLngBounds();
		for ( let i = 0; i < properties.length; i++ ) {
			const lat = properties[ i ].lat_lng.lat;
			const lng = properties[ i ].lat_lng.lng;
			const { title, permalink } = properties[ i ];
			const formattedPrice = properties[ i ].formatted_price;
			const textAfterPrice = properties[ i ].text_after_price;
			const typeName = properties[ i ].type_name;
			const typeLink = properties[ i ].type_link;
			const thumbnail = properties[ i ].thumbnail_url;

			const propertyId = properties[ i ].id;

			const marker = new google.maps.Marker( {
				position: new google.maps.LatLng( lat, lng ),
				title,
				map,
				id: propertyId,
			} );
			bounds.extend( marker.position );
			markers.push( marker );
			const popup = getPopupContent( permalink, thumbnail, title, formattedPrice, textAfterPrice, typeName, typeLink );
			infowindow = new google.maps.InfoWindow();

			google.maps.event.addListener( marker, 'click', function() {
				infowindow.setContent( popup );
				infowindow.open( map, marker );
			} );
		}
		map.fitBounds( bounds );
	}

	return map;
};

const getPopupContent = ( permalink, thumbnail, title, formattedPrice, textAfterPrice, typeName, typeLink ) => {
	let popup = `<div class="realpress-popup-property-header">
						<a target="_blank" href="${ permalink }"><img src="${ thumbnail }" alt="#"></a>
					</div>
					<div class="realpress-popup-property-body">
						<h2><a target="_blank" href="${ permalink }">${ title }</a></h2>
						<div class="realpress-popup-property-price">
							<span>${ formattedPrice }</span>&nbsp;<span>${ textAfterPrice }</span>
						</div>`;
	if ( typeName && typeLink ) {
		popup += `<div class="realpress-popup-type"><a target="_blank" href="${ typeLink }">${ typeName }</a></div>`;
	}
	popup += `</div>`;

	return popup;
};

export { invalidateSize, displayPropertyMapBound, openPopup, closePopup };
