import { renderPrice } from '../../mixins/price';
import { show, hide } from 'slidetoggle';

const mcWidgetNodes = document.querySelectorAll( '.widget_realpress_mortgage_calculator' );
const { __ } = wp.i18n;
const realpressMortgageCalculatorWidget = () => {
	if ( ! mcWidgetNodes ) {
		return false;
	}

	for ( let i = 0; i < mcWidgetNodes.length; i++ ) {
		const mcWidgetNode = mcWidgetNodes[ i ];
		handleMortgageCalculatorWidget( mcWidgetNode );
	}
};

const handleMortgageCalculatorWidget = ( mcWidgetNode ) => {
	const messageNode = mcWidgetNode.querySelector( '.realpress-form-message' );
	const totalAmountNode = mcWidgetNode.querySelector( 'input[name="total_amount"]' );
	const downPaymentNode = mcWidgetNode.querySelector( 'input[name="down_payment"]' );
	const loanTermsNode = mcWidgetNode.querySelector( 'input[name="loan_terms"]' );
	const interestRateNode = mcWidgetNode.querySelector( 'input[name="interest_rate"]' );
	const resultNode = mcWidgetNode.querySelector( '.realpress-mc-widget-result' );
	const totalAmountDefaultVal = totalAmountNode.value;
	const downPaymentDefaultVal = downPaymentNode.value;
	const loanTermsDefaultVal = loanTermsNode.value;
	const interestRateDefaultVal = interestRateNode.value;

	mcWidgetNode.querySelector( 'button.realpress-mc-widget-calculate' ).addEventListener( 'click', function() {
		const totalAmount = totalAmountNode.value;
		const downPayment = downPaymentNode.value;
		const loanTerms = loanTermsNode.value;
		const interestRate = interestRateNode.value;

		if ( totalAmount && downPayment && loanTerms && interestRate ) {
			const paymentNumbers = loanTerms * 12;
			const amountFinanced = totalAmount - downPayment;
			const monthInterestRate = interestRate / 12 / 100;
			let principalInterestPayment = totalAmount * monthInterestRate * ( Math.pow( 1 + monthInterestRate, paymentNumbers ) ) / ( Math.pow( 1 + monthInterestRate, paymentNumbers ) - 1 );
			if ( isNaN( principalInterestPayment ) ) {
				principalInterestPayment = 0;
			}
			const totalMorgageWithInterest = principalInterestPayment * loanTerms * 12;
			const totalWithDownPayment = totalMorgageWithInterest + parseFloat( downPayment );

			resultNode.querySelector( '.realpress-mc-widget-principal_amount' ).innerHTML = renderPrice( amountFinanced );
			resultNode.querySelector( '.realpress-mc-widget-years' ).innerHTML = loanTerms;
			resultNode.querySelector( '.realpress-mc-widget-monthly-payment' ).innerHTML = renderPrice( principalInterestPayment );
			resultNode.querySelector( '.realpress-mc-widget-payable-with-interest' ).innerHTML = renderPrice( totalMorgageWithInterest );
			resultNode.querySelector( '.realpress-mc-widget-total-downpayment' ).innerHTML = renderPrice( totalWithDownPayment );
			// resultNode.style.display = 'block';
			show( resultNode, {
				miliseconds: 300,
			} );
		} else {
			messageNode.classList.add( 'failed' );
			messageNode.innerHTML = __( 'Please fill out all fields', 'realpress' );
			messageNode.scrollIntoView( { behavior: 'smooth' } );
		}
	} );

	mcWidgetNode.querySelector( 'button.realpress-mc-widget-reset' ).addEventListener( 'click', function() {
		totalAmountNode.value = totalAmountDefaultVal;
		downPaymentNode.value = downPaymentDefaultVal;
		loanTermsNode.value = loanTermsDefaultVal;
		interestRateNode.value = interestRateDefaultVal;
		// resultNode.style.display = 'none';
		hide( resultNode, {
			miliseconds: 300,
		} );
	} );
};

export default realpressMortgageCalculatorWidget;
