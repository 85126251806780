import { toggle, hide } from 'slidetoggle';

const realpressFloorPlans = () => {
	const floorPlanNode = document.querySelector( '.realpress-floor-plan-section' );
	if ( ! floorPlanNode ) {
		return;
	}
	const floorPlanInnerNodes = floorPlanNode.querySelectorAll( '.realpress-floor-plan-inner' );
	let activeFloorInnerNode;
	for ( let i = 0; i < floorPlanInnerNodes.length; i++ ) {
		const floorPlanInnerNode = floorPlanInnerNodes[ i ];
		floorPlanInnerNode.querySelector( 'div.realpress-floor-heading' ).addEventListener( 'click', function() {
			if ( floorPlanInnerNode.classList.contains( 'active' ) ) {
				floorPlanInnerNode.classList.remove( 'active' );
			} else {
				if ( !! activeFloorInnerNode ) {
					activeFloorInnerNode.classList.remove( 'active' );
					const activeDropDownNode = activeFloorInnerNode.querySelector( '.realpress-floor-dropdown' );
					hide( activeDropDownNode, {
						miliseconds: 300,
					} );
				}

				floorPlanInnerNode.classList.add( 'active' );
				activeFloorInnerNode = floorPlanInnerNode;
			}

			const dropdownNode = floorPlanInnerNode.querySelector( '.realpress-floor-dropdown' );
			toggle( dropdownNode, {
				miliseconds: 300,
			} );
		} );
	}
};

export default realpressFloorPlans;
