import { addQueryArgs } from '@wordpress/url';
import { getOrderParams } from '../mixins/review-list';

const reviewNode = document.querySelector( '.realpress-property-review-wrap' );
let headingNode, averageRatingNode, reviewContainerNode, sortNode, paginationWrapNode, paginationNode, formNode,
	propertyId, restNamespace;
let query;

const init = () => {
	headingNode = reviewNode.querySelector( '.realpress-review-heading' );
	averageRatingNode = reviewNode.querySelector( '.realpress-review' );
	reviewContainerNode = reviewNode.querySelector( '.realpress-reviews-container' );
	sortNode = reviewNode.querySelector( '.realpress-sort-by select' );
	paginationWrapNode = reviewNode.querySelector( '.realpress-pagination' );
	formNode = document.querySelector( '#realpress-property-review-form form' );
	propertyId = REALPRESS_SINGLE_PROPERTY_PAGE.property_id || '';
	restNamespace = REALPRESS_GLOBAL_OBJECT.rest_namespace || '';
	query = { page: 1, orderby: 'date', order: 'desc', property_id: propertyId, type: 'property' };
};

const realpressReview = () => {
	if ( ! reviewNode ) {
		return;
	}
	init();

	if ( sortNode ) {
		getReview( query );
		//sort
		sortNode.addEventListener( 'change', function( event ) {
			let page = 1;

			paginationNode = paginationWrapNode.querySelector( '.realpress-pagination' );

			if ( paginationNode ) {
				page = paginationNode.querySelector( 'li a.current' ).getAttribute( 'data-page' );
			}

			const selected = event.target.value;
			const sortParams = getOrderParams( selected );
			query = { ...query, ...sortParams, page };
			getReview( query );
			reviewNode.scrollIntoView( { behavior: 'smooth' } );
		} );
	}

	if ( !! formNode ) {
		addReview();
	}
};

const addReview = () => {
	formNode.querySelector( '#realpress-submit-review' ).addEventListener( 'click', function( event ) {
		event.preventDefault();
		this.disabled = true;
		const messageNode = formNode.querySelector( '.realpress-form-message' );
		const rating = formNode.querySelector( '#realpress-review-rating' ).value;
		const content = formNode.querySelector( '#realpress-review-content' ).value;
		const data = { property_id: propertyId, rating, content, type: 'property' };

		wp.apiFetch( {
			path: '/' + restNamespace + '/comments', method: 'POST', data,
		} ).then( ( res ) => {
			if ( !! res.status && res.status === 'success' ) {
				messageNode.classList.remove( 'failed' );
				messageNode.classList.add( 'success' );
				messageNode.innerHTML = res.msg;
				getReview( query );
				reviewNode.scrollIntoView( { behavior: 'smooth' } );
			} else {
				messageNode.classList.remove( 'success' );
				messageNode.classList.add( 'failed' );
				messageNode.innerHTML = res.msg;
				messageNode.scrollIntoView( { behavior: 'smooth' } );
			}
		} ).catch( ( err ) => {
			console.log( err );
		} ).finally( () => {
			this.disabled = false;
		} );
	} );
};

const getReview = ( data = {} ) => {
	reviewContainerNode.style.opacity = 0.4;
	wp.apiFetch( {
		path: addQueryArgs( '/' + restNamespace + '/comments', data ), method: 'GET',
	} ).then( ( res ) => {
		if ( !! res.data.content ) {
			reviewContainerNode.innerHTML = res.data.content;
		} else {
			reviewContainerNode.innerHTML = '';
		}

		if ( !! res.data.total ) {
			headingNode.innerHTML = res.data.total;
		}

		if ( !! res.data.average_rating ) {
			averageRatingNode.innerHTML = res.data.average_rating;
		}

		if ( !! res.data.pagination ) {
			paginationWrapNode.innerHTML = res.data.pagination;
		}

		paginationNode = paginationWrapNode.querySelector( '.realpress-pagination-nav' );
		if ( !! paginationNode ) {
			const pageNodes = paginationNode.querySelectorAll( 'a' );
			for ( let i = 0; i < pageNodes.length; i++ ) {
				pageNodes[ i ].addEventListener( 'click', function( event ) {
					event.preventDefault();
					const page = this.getAttribute( 'data-page' );
					getReview( { ...data, page } );
					reviewNode.scrollIntoView( { behavior: 'smooth' } );
				} );
			}
		}
	} ).catch( ( err ) => {
		console.log( err );
	} ).finally( () => {
		reviewContainerNode.style.opacity = 1;
	} );
};

export default realpressReview;
