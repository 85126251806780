import { addQueryArgs } from '@wordpress/url';

const realpressSimilarProperty = () => {
	const similarPropertyNode = document.querySelector( '.realpress-similar-property' );
	if ( ! similarPropertyNode ) {
		return;
	}
	const postId = REALPRESS_SINGLE_PROPERTY_PAGE.property_id || '';
	let query = { template: 'similar_property', posts_per_page: 4, post_id: postId };
	const restNamespace = REALPRESS_GLOBAL_OBJECT.rest_namespace || '';



	if ( similarPropertyNode.getAttribute( 'data-loop-item-id' ) ) {
		query = { ...query, 'loop-item-id': similarPropertyNode.getAttribute( 'data-loop-item-id' ) };
	}

	wp.apiFetch( {
		path: addQueryArgs( '/' + restNamespace + '/properties', query ), method: 'GET',
	} ).then( ( res ) => {
		if ( res.data.content ) {
			similarPropertyNode.innerHTML = res.data.content;
		} else if ( res.msg ) {
			similarPropertyNode.innerHTML = res.msg;
		} else {
			similarPropertyNode.innerHTML = '';
		}
	} ).catch( ( err ) => {
		console.log( err );
	} ).finally( () => {
	} );
};

export default realpressSimilarProperty;
