import { default as OSM } from './single-property/osm-map';
import { default as GoogleMap } from './single-property/google-map';
import realpressReview from './single-property/review';
import realpressGallerySlider from './single-property/gallery-slider';
import realpressSimilarSilder from './single-property/similar-slide';
import realpressMediaNavigation from './single-property/media-navigation';
import realpressContactForm from './widgets/contact-form';
import realpressFloorPlans from './single-property/floor-plans';
import realpressMortgageCalculator from './single-property/mortgage-calculator';
import realpressSimilarProperty from './single-property/similar-property';
import realpressMortgageCalculatorWidget from './widgets/mortgage-calculator';

document.addEventListener( 'DOMContentLoaded', ( event ) => {
	OSM();
	GoogleMap();
	realpressReview();
	realpressGallerySlider();
	realpressMediaNavigation();
	realpressContactForm();
	realpressFloorPlans();
	realpressMortgageCalculator();
	realpressMortgageCalculatorWidget();
	realpressSimilarProperty();
	realpressSimilarSilder();
} );
